<template>
  <div class="user-store-details-responsive">
    <div
      v-if="that && that.storeList"
      style="height: inherit"
      class="user-store-details store-ui p-0"
    >
      <div class="mobile-pad-0 d-flex flex-wrap">
        <div class="store-image-section-height">
          <div class="store-main-image d-flex justify-content-center align-items-center overflow-hidden p-0 w-100 relative">
            <b-img
              class="w-100 h-100 img-transition cursor-pointer"
              :src="that.mainPhoto ? $helpers.imageHelper(that.mainPhoto) : require('@/assets/images/app-images/banner-store-image.webp')"
              @click="that.showPopup = true"
              @error="$helpers.imageError"
            />
          </div>
        </div>
      </div>
      <div class="px-2">
        <div class="d-flex justify-content-between align-items-center py-1">
          <div>
            <p class="font-18 font-weight-bolder text-uppercase mb-0">
              {{ that.storeList.store_name }}
            </p>
          </div>
          <div>
            <b-img
              :class="{ 'gray-scale-icon': !that.storeList.rating || that.storeList.rating <= 0 }"
              style="height: 25px"
              :src="require('@/assets/images/app-icons/rating.png')"
            />
            {{ that.storeList.rating }}
          </div>
        </div>
        <div
          v-if="that.storeList.store_location"
          class="d-flex wrapper-address-line address-lines mt-0 cursor-pointer"
          @click="that.openStoreMap"
        >
          <b-img
            style="height: 25px"
            :src="require('@/assets/images/app-icons/map.png')"
          />
          <p class="mb-0 ml-1">
            {{ that.storeList.store_location }}
          </p>
        </div>
        <div class="wrapper-address-line address-lines mt-0">
          <div class="d-flex">
            <b-img
              style="height: 25px"
              :src="require('@/assets/images/app-icons/Timing.png')"
            />
            <div
              style="position: relative"
              class="w-100 d-flex align-items-center"
              @click="that.toggleTimings = !that.toggleTimings"
            >
              <feather-icon
                style="position: absolute; right: 10px"
                :icon="that.toggleTimings ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                size="15"
              />
              <p
                v-if="that.storeTimings"
                class="mb-0 ml-1"
              >
                Today: {{ that.storeTimings.store_start_time.slice(0, 5) }} - {{ that.storeTimings.store_end_time.slice(0, 5) }}
              </p>
              <p
                v-else
                class="mb-0 ml-1"
              >
                Today: Closed
              </p>
            </div>
          </div>
          <template v-if="that.toggleTimings">
            <table class="table table-borderless">
              <tbody>
                <tr
                  v-for="(data, index) of that.weekDays"
                  :key="index"
                  :class="{ 'font-weight-bold': that.currentDay === data }"
                >
                  <td class="py-0 pl-4">
                    {{ data }}
                  </td>
                  <td
                    v-if="that.storeTimingData(data)"
                    class="py-0 text-right"
                  >
                    {{ that.storeTimingData(data).store_start_time.slice(0, 5) }} - {{ that.storeTimingData(data).store_end_time.slice(0, 5) }}
                  </td>
                  <td
                    v-else
                    class="py-0 text-right"
                  >
                    Closed
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </div>
        <div
          v-if="that.storeList.contact_number"
          class="d-flex wrapper-address-line address-lines mt-0 cursor-pointer"
          @click="that.openPhone"
        >
          <b-img
            style="height: 25px"
            :src="require('@/assets/images/app-icons/Call.png')"
          />
          <p class="mb-0 ml-1">
            +{{ that.storeList.country_code }}{{ that.storeList.contact_number }}
          </p>
        </div>
        <b-row class="d-flex justify-content-start align-items-center mb-1">
          <b-col cols="auto" class="pr-1">
            <b-img
              height="25"
              :src="require('@/assets/images/app-icons/shopping-bag.png')"
            />
          </b-col>
          <b-col cols="auto" class="pl-0">
            <p class="mb-0">
              {{ $t('Minimum order value') }} {{ that.storeList.currency_symbol }} {{ that.storeList.offer_min_amount }}
            </p>
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-start align-items-center mb-1">
          <b-col cols="auto" class="pr-1">
            <b-img
              height="25"
              :src="require('@/assets/images/app-icons/express-delivery.png')"
            />
          </b-col>
          <b-col cols="auto" class="pl-0">
            <p class="mb-0">
              {{ $t('Delivery within') }} {{ that.storeList.estimated_delivery_time ? that.storeList.estimated_delivery_time : 'NA' }}
              <span style="text-transform: lowercase">{{ that.storeList.estimated_delivery_time_type }}</span>
            </p>
          </b-col>
        </b-row>
        <div class="d-flex address-lines mt-1 justify-content-between">
          <b-row class="d-flex align-content-center flex-wrap pl-1">
            <b-col v-if="that.storeList.facebook" cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="social-icon-height cursor-pointer"
                :src="require('@/assets/images/icons/social/icons8-facebook.svg')"
                @click="that.goToSocial(`${that.storeList.facebook}`, '_BLANK')"
              />
            </b-col>
            <b-col v-if="that.storeList.instagram" cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="social-icon-height cursor-pointer"
                :src="require('@/assets/images/icons/social/icons8-instagram.svg')"
                @click="that.goToSocial(`${that.storeList.instagram}`, '_BLANK')"
              />
            </b-col>
            <b-col v-if="that.storeList.linkedin" cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="social-icon-height cursor-pointer"
                :src="require('@/assets/images/icons/social/icons8-linkedin.svg')"
                @click="that.goToSocial(`${that.storeList.linkedin}`, '_BLANK')"
              />
            </b-col>
            <b-col v-if="that.storeList.telegram" cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="social-icon-height cursor-pointer"
                :src="require('@/assets/images/icons/social/icons8-telegram-app.svg')"
                @click="that.goToSocial(`${that.storeList.telegram}`, '_BLANK')"
              />
            </b-col>
            <b-col v-if="that.storeList.twitter" cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="social-icon-height cursor-pointer"
                :src="require('@/assets/images/icons/social/icons8-twitter.png')"
                @click="that.goToSocial(`${that.storeList.twitter}`, '_BLANK')"
              />
            </b-col>
            <b-col v-if="that.storeList.whatsapp" cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="social-icon-height cursor-pointer"
                :src="require('@/assets/images/icons/social/icons8-whatsapp.svg')"
                @click="that.goToSocial(`${that.storeList.whatsapp}`, '_BLANK')"
              />
            </b-col>
            <b-col v-if="that.storeList.messenger" cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="social-icon-height cursor-pointer"
                :src="require('@/assets/images/icons/social/icons8-facebook-messenger.svg')"
                @click="that.goToSocial(`${that.storeList.messenger}`, '_BLANK')"
              />
            </b-col>
            <b-col v-if="that.storeList.tiktok" cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="social-icon-height cursor-pointer"
                :src="require('@/assets/images/icons/social/icons8-tiktok.svg')"
                @click="that.goToSocial(`${that.storeList.tiktok}`, '_BLANK')"
              />
            </b-col>
            <b-col v-if="that.storeList.viber" cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="social-icon-height cursor-pointer"
                :src="require('@/assets/images/icons/social/icons8-viber.svg')"
                @click="that.goToSocial(`${that.storeList.viber}`, '_BLANK')"
              />
            </b-col>
            <b-col v-if="that.storeList.youtube" cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="social-icon-height cursor-pointer"
                :src="require('@/assets/images/icons/social/icons8-youtube.svg')"
                @click="that.goToSocial(`${that.storeList.youtube}`, '_BLANK')"
              />
            </b-col>
            <b-col v-if="that.storeList.pintrest" cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="social-icon-height cursor-pointer"
                :src="require('@/assets/images/icons/social/icons8-pinterest.svg')"
                @click="that.goToSocial(`${that.storeList.pintrest}`, '_BLANK')"
              />
            </b-col>
            <b-col v-if="that.storeList.skype" cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="social-icon-height cursor-pointer"
                :src="require('@/assets/images/icons/social/icons8-skype.svg')"
                @click="that.goToSocial(`${that.storeList.skype}`, '_BLANK')"
              />
            </b-col>
          </b-row>
          <b-row class="d-flex align-content-center flex-wrap pl-1">
            <b-col cols="auto" class="px-0 pr-social-icons">
              <b-img
                class="social-icon-height cursor-pointer"
                :src="require('@/assets/images/app-icons/Share.png')"
                @click="that.copyUrl"
              />
            </b-col>
          </b-row>
        </div>
        <div
          v-if="that.optionsLoaded.overview && that.storeList.overviews"
          class="wrap-overview"
        >
          <p
            class="store-title-info text-left font-weight-bolder text-uppercase"
            @click="seeOverview"
          >
            {{ $t('Overview') }} <feather-icon
              :icon="toggleOverview ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              size="15"
            />
          </p>
          <!-- eslint-disable vue/no-v-html -->
          <p
            v-if="toggleOverview"
            v-html="that.storeList.overviews.overview"
          />
          <!--eslint-enable-->
        </div>
        <div class="wrap-order-now d-flex justify-content-between align-items-end">
          <p class="store-title-info text-left font-weight-bolder text-uppercase">
            Order Now
          </p>
          <p
            class="cursor-pointer"
            style="margin-bottom: 6px"
            @click="seeOrderNow"
          >
            See menu
          </p>
        </div>
        <div
          v-if="that.userOffers.length > 0"
          class="wrap-order-now d-flex justify-content-between align-items-end"
        >
          <p class="store-title-info text-left font-weight-bolder text-uppercase">
            Promocodes
          </p>
          <p
            class="cursor-pointer"
            style="margin-bottom: 6px"
            @click="seePromocodes"
          >
            See promocodes
          </p>
        </div>
        <div
          v-if="that.isFoodCategory"
          class="wrap-order-now d-flex justify-content-between align-items-end"
        >
          <p class="store-title-info text-left font-weight-bolder text-uppercase">
            {{ $t('BOOK A TABLE') }}
          </p>
          <p
            class="cursor-pointer"
            style="margin-bottom: 6px"
            @click="seeBookNow"
          >
            {{ $t('Book Now') }}
          </p>
        </div>
        <div class="menu-services">
          <div class="d-flex justify-content-between align-items-end">
            <p class="store-title-info text-left font-weight-bolder text-uppercase">
              {{ $t('Menu & Photos') }}
            </p>
            <span
              style="margin-bottom: 6px"
              @click="seePhotos"
            >{{ $t('See all') }} ({{ that.otherPhotos.length + (that.storeList.photos ? that.storeList.photos.filter(x => x.is_menu === 1).length : 0) }})</span>
          </div>
          <div
            v-if="that.storeList.photos"
            class="menus-and-photos"
          >
            <b-img
              v-for="(item, index) in that.otherPhotos"
              :key="index"
              data-hide="true"
              class="store-photos-tab store-shadow img-transition cursor-pointer"
              :src="$helpers.imageHelper(item.photo)"
              @click="that.showPopupImage(item.photo)"
              @error="$helpers.imageError"
            />
            <b-img
              v-for="(item, index) in that.storeList.photos.filter(x => x.is_menu === 1)"
              :key="index"
              data-hide="true"
              class="store-photos-tab store-shadow img-transition cursor-pointer"
              :src="$helpers.imageHelper(item.photo)"
              @click="that.showPopupImage(item.photo)"
            />
          </div>
        </div>
        <div class="wrap-order-now d-flex justify-content-between align-items-end">
          <p class="store-title-info text-left font-weight-bolder text-uppercase">
            {{ $t('Reviews') }}
          </p>
          <p
            class="cursor-pointer"
            style="margin-bottom: 6px"
            @click="seeReviewsNow"
          >
            {{ $t('See all') }}
          </p>
        </div>
      </div>
    </div>
    <b-modal
      id="reviewsModal"
      v-model="seeReviewModal"
      :no-close-on-backdrop="true"
      class="ecommerce-application"
      :title="$t('Review & Ratings')"
      size="lg"
      hide-footer
      @hidden="seeReviewModal = false"
    >
      <no-data-available
        v-if="!that.optionsLoaded.reviews && !that.storeItemLoading"
        :type="'reviews'"
      />
      <b-skeleton-table
        v-else-if="that.storeItemLoading"
        :rows="5"
        :columns="1"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
      <div v-if="that.optionsLoaded.reviews">
        <div
          v-for="(item, index) of that.storeList.reviews"
          :key="index"
        >
          <div
            class="col-md-12 p-0 d-flex mb-1"
            :class="{ 'border-bottom': index < that.storeList.reviews.length - 1 }"
          >
            <b-media class="p-0 align-items-center">
              <template #aside>
                <b-avatar />
              </template>
            </b-media>
            <div class="review-wrapper-store">
              <h6 class="m-0">
                {{ item.user ? `${item.user.first_name} ${item.user.last_name}` : '' }}
              </h6>
              <p
                v-if="item.order_id"
                class="mb-0 font-weight-bolder"
              >
                ({{ $t('Order No: ') }} {{ item.order ? item.order.store_order_invoice_id : item.order_id }})
              </p>
              <p class="m-0">
                {{ item.review }}
              </p>
              <div class="mb-0">
                <ul
                  class="unstyled-list list-inline"
                  style="margin-bottom: 5px"
                >
                  <li
                    v-for="star in 5"
                    :key="star"
                    class="ratings-list-item"
                    :class="{ 'ml-25': star - 1 }"
                  >
                    <feather-icon
                      icon="StarIcon"
                      size="16"
                      :class="[{ 'fill-current': star <= item.rate }, star <= item.rate ? 'theme-color' : 'text-muted']"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            v-if="item.review_response"
            class="col-md-10 offset-1 pl-0"
          >
            <div class="p-0 d-flex mb-1">
              <b-media class="p-0 align-items-center">
                <template #aside>
                  <b-avatar />
                </template>
              </b-media>
              <div class="review-wrapper-store">
                <h6 class="m-0">
                  <h6
                    v-if="that.storeList"
                    class="mb-0"
                  >
                    {{ that.storeList.store_name }}
                  </h6>
                  <p class="small mb-0">
                    {{ $t('Comment on:') }} {{ formatDate(item.review_response.created_at) }}
                  </p>
                </h6>
                <p class="mt-0">
                  {{ item.review_response.review }}
                </p>
                <div class="mb-0">
                  <ul
                    class="unstyled-list list-inline"
                    style="margin-bottom: 5px"
                  >
                    <li
                      v-for="star in 5"
                      :key="star"
                      class="ratings-list-item"
                      :class="{ 'ml-25': star - 1 }"
                    >
                      <feather-icon
                        icon="StarIcon"
                        size="16"
                        :class="[{ 'fill-current': star <= item.review_response.rate }, star <= item.review_response.rate ? 'theme-color' : 'text-muted']"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex">
        <div class="col-md-12 store-shadow wrap-store-review mt-3">
          <post-review />
        </div>
      </div> -->
    </b-modal>
    <b-modal
      id="photosModal"
      v-model="seePhotoModal"
      :no-close-on-backdrop="true"
      class="ecommerce-application"
      :title="$t('Photos')"
      size="lg"
      hide-footer
      @hidden="seePhotoModal = false"
    >
      <no-data-available
        v-if="!that.optionsLoaded.photos && !that.optionsLoaded.menu && !that.storeItemLoading"
        :type="'menu'"
      />
      <b-skeleton-table
        v-else-if="that.storeItemLoading"
        :rows="5"
        :columns="1"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
      <div v-if="that.optionsLoaded.photos || that.optionsLoaded.menu">
        <div
          v-if="that.storeList.photos"
          class="menus-and-photos"
        >
          <b-img
            v-for="(item, index) in that.storeList.photos"
            :key="index"
            class="store-photos-tab store-shadow img-transition cursor-pointer"
            :src="$helpers.imageHelper(item.photo)"
            @click="that.showPopupImage(item.photo)"
          />
        </div>
      </div>
    </b-modal>
    <b-modal
      id="promocodesModal"
      v-model="sseePromocodesModal"
      :no-close-on-backdrop="true"
      class="ecommerce-application"
      :title="$t('Promocodes')"
      size="lg"
      hide-footer
      @hidden="sseePromocodesModal = false"
    >
      <template v-if="that.userOffers.length > 0">
        <div
          v-for="(coupon, index) of that.userOffers"
          :key="index"
          class="col-md-12"
          @click="that.goToPromocode(coupon)"
        >
          <b-card class="cursor-pointer">
            <b-card-header class="d-block mb-1 px-0">
              <b-card-title style="word-break: break-all;">
                {{ coupon.promo_description }}
              </b-card-title>
            </b-card-header>
            <b-card-body class="p-0">
              <div class="row">
                <div class="col-7">
                  <span
                    class="badge badge-primary badge-pill mr-1 mb-1"
                    style="padding: 10px 20px; font-size: 20px"
                  >{{ coupon.promo_code }}</span>
                </div>
                <div class="col-5">
                  <img
                    :src="$helpers.imageHelper(coupon.picture)"
                    class="w-100"
                    @error="$helpers.imageError"
                  >
                </div>
              </div>
              <!-- <p class="mb-0 small">
                {{ $t('Valid Till') }}: {{ coupon.expiration }}
              </p> -->
            </b-card-body>
          </b-card>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="bookNowModal"
      v-model="seeBookNowModal"
      :no-close-on-backdrop="true"
      class="ecommerce-application"
      :title="$t('Book Now')"
      size="lg"
      hide-footer
      @hidden="seeBookNowModal = false"
    >
      <div class="wrap-book-a-table">
        <div class="row container mx-auto mobile-pad-0 mt-1">
          <div class="col-md-12 mb-2">
            <div class="d-flex wrap-store-options align-items-center justify-content-center mx-auto w-50">
              <b-img
                style="height: 25px"
                class="mr-2"
                :src="require('@/assets/images/app-icons/teamwork.png')"
              />
              <p class="mb-0">
                {{ $t('NO OF GUESTS') }}
              </p>
            </div>
            <b-form-spinbutton
              style="width: 30%"
              class="mx-auto mt-1 spinner-wrapper"
              :value="1"
              min="1"
              size="sm"
            />
          </div>
          <div class="col-md-12 mobile-pad-0">
            <div class="d-flex wrap-store-options align-items-center justify-content-center mx-auto w-50 mb-1">
              <b-img
                style="height: 25px"
                class="mr-2"
                :src="require('@/assets/images/app-icons/Timing.png')"
              />
              <p class="mb-0">
                {{ $t('DATE & TIME') }}
              </p>
            </div>
            <custom-calender
              ref="bookingCalenderRefMobile"
              :timing="that.storeCurrentTimings"
              :all-timings="that.storeSlotInfo"
              :store-slot-info="that.storeSlotInfo"
              @submit="that.bookTableOnline"
            />
          </div>
          <div class="col-md-12">
            <div class="d-flex wrap-store-options align-items-center justify-content-center mx-auto w-50 mb-1">
              <b-img
                style="height: 25px"
                class="mr-2"
                :src="require('@/assets/images/app-icons/comments.png')"
              />
              <p class="mb-0">
                {{ $t('COMMENTS') }}
              </p>
            </div>
            <textarea
              class="form-control"
              placeholder="Enter your comments here..."
            />
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="orderNowModal"
      v-model="seeOrderMenuModal"
      :no-close-on-backdrop="true"
      class="ecommerce-application"
      :title="$t('Check the list')"
      size="lg"
      hide-footer
      @hidden="seeOrderMenuModal = false"
    >
      <div class="wrap-online-order-section">
        <no-data-available
          v-if="!that.optionsLoaded.products && !that.storeItemLoading"
          :type="'product'"
        />
        <b-skeleton-table
          v-else-if="that.storeItemLoading"
          :rows="5"
          :columns="1"
          :table-props="{ bordered: true, striped: true }"
        ></b-skeleton-table>
        <div
          v-if="that.optionsLoaded.products"
          class="col-md-12 px-0"
        >
          <section id="ecommerce-header">
            <div class="row">
              <div class="col-sm-12 p-0">
                <div class="col-md-12">
                  <div class="ecommerce-header-items">
                    <div class="result-toggler d-flex justify-content-between align-item-center">
                      <!-- <feather-icon
                      icon="MenuIcon"
                      class="d-block d-lg-none"
                      size="21"
                      @click="that.mqShallShowLeftSidebar = true"
                    /> -->
                      <div class="search-results">
                        {{ $t('Showing') }} {{ that.filteredStore.length }} {{ $t('of') }} {{ that.storeList.products ? that.storeList.products.length : 0 }} {{ $t('results') }}
                      </div>
                      <div class="view-options d-flex">
                        <cart-dropdown
                          v-if="isLoggedIn"
                          class="store-cart-option-button"
                        />
                        <!-- Item View Radio Button Group  -->
                        <b-form-radio-group
                          v-model="that.itemView"
                          class="ml-1 list item-view-radio-group"
                          buttons
                          size="sm"
                          button-variant="outline-primary"
                        >
                          <b-form-radio
                            v-for="option in that.itemViewOptions.filter(x => x.icon == 'ListIcon')"
                            :key="option.value"
                            :value="option.value"
                          >
                            <feather-icon
                              :icon="option.icon"
                              size="18"
                            />
                          </b-form-radio>
                        </b-form-radio-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div class="body-content-overlay" />

          <div class="ecommerce-searchbar mt-1 mb-1">
            <b-row>
              <b-col cols="12">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="that.searchQuery"
                    placeholder="Search..."
                    class="search-product"
                    @keyup="that.beginSearch"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="SearchIcon"
                      class="text-muted"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </div>

          <section :class="that.itemView">
            <app-collapse accordion>
              <app-collapse-item
                v-for="(categoryGroup, index) in that.storeCategoryGroups"
                :key="index"
                :title="`${categoryGroup.store_category_name} (${that.filteredStore.filter(x => x.store_category_id === categoryGroup.id).length})`"
              >
                <b-card
                  v-for="product in that.filteredStore.filter(x => x.store_category_id === categoryGroup.id)"
                  :key="product.id"
                  class="ecommerce-card pt-pb"
                  :class="{ 'disable-effect-stock': !product.is_available }"
                  no-body
                >
                  <div class="item-img text-center set-extra-images py-0">
                    <div
                      class="d-flex extra-image-ui"
                      :class="{ 'justify-content-end': !product.is_veg }"
                    >
                      <b-img
                        v-if="product.is_veg"
                        class="food-type-image"
                        :src="$helpers.getIcons(product.is_veg ? (product.is_veg === 'Non Veg' ? 'non-veg' : 'veg') : '')"
                      />
                      <div class="wrapper-images d-flex">
                        <b-img
                          v-if="product.is_best_seller === 1"
                          class="best-seller-image"
                          :src="$helpers.getIcons('bestseller')"
                        />
                        <b-img
                          v-if="product.is_recommended === 1"
                          class="recommended-image"
                          :src="$helpers.getIcons('recommended')"
                        />
                      </div>
                    </div>
                    <b-img
                      v-if="!product.is_available"
                      class="out-of-stock-image"
                      :src="$helpers.getIcons('stock-out')"
                    />
                    <b-link class="w-100">
                      <figure
                        @mouseenter="$superApp.helper.zoomMagic"
                        @mouseout="$superApp.helper.zoomOut"
                      >
                        <v-zoomer
                          :zoomed.sync="$superApp.zoomed"
                          :mouse-wheel-to-zoom.sync="$superApp.mouseWheelToZoom"
                        >
                          <b-img
                            :alt="`${product.item_name}s`"
                            fluid
                            class="card-img-top"
                            :src="$helpers.imageHelper(product.picture)"
                            @error="$helpers.imageError"
                          />
                        </v-zoomer>
                      </figure>
                    </b-link>
                  </div>

                  <div class="d-flex flex-wrap py-0">
                    <!-- Product Details -->
                    <b-card-body>
                      <h6 class="item-names">
                        <b-link class="text-body">
                          {{ product.item_name }}
                          <span v-if="product.unit_value && product.unit_id && product.main_unit">({{ product.unit_value }} {{ product.main_unit.name }})</span>
                        </b-link>
                      </h6>
                      <b-card-text
                        v-if="product.item_description"
                        class="item-description d-block text-break auto-text"
                        :class="{ 'mb-0': that.itemView === 'list-view' }"
                      >
                        {{ product.showFull ? product.item_description : that.getText(product.item_description) }}
                        <span
                          v-if="that.canShowLabel(product.item_description)"
                          class="small font-weight-bold text-right"
                          style="word-break: keep-all !important;margin-top: 4px; float: right"
                          @click="product.showFull = !product.showFull"
                        >
                          <feather-icon
                            :icon="product.showFull ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                            class="cursor-pointer"
                            size="15"
                          />
                        </span>
                      </b-card-text>
                    </b-card-body>
                    <div class="card-body d-flex align-items-center mb-1 py-0">
                      <b-card-text
                        v-if="that.itemView === 'list-view'"
                        class="item-description offer-visible mb-0"
                      >
                        {{ that.storeList.currency_symbol }} {{ product.product_offer }}
                      </b-card-text>
                      <div
                        class="item-options text-center list-view-manage mb-0 cart-item-qty"
                        style="width: 50%"
                      >
                        <form-spin-button
                          v-if="that.isAddedToCart(product)"
                          :product="product"
                          :cart-product="that.multipleProduct(product)"
                          @value-changed="that.addItemToCart"
                        />
                        <b-button
                          v-if="!that.isAddedToCart(product)"
                          variant="primary"
                          tag="a"
                          class="btn-cart"
                          @click="that.setItemAddOnData(product)"
                        >
                          <span class="text-uppercase">{{ that.isAddedToCart(product) ? 'Added to cart' : 'Add' }}</span>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </b-card>
              </app-collapse-item>
            </app-collapse>
          </section>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BImg,
  BFormRadioGroup,
  BSkeletonTable,
  BFormSpinbutton,
  BMedia,
  BAvatar,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BCard,
  BLink,
  BCardBody,
  BCardText,
  BButton,
  BModal,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import moment from 'moment'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import CartDropdown from '@/@core/layouts/components/app-navbar/components/CartDropdown.vue'
// import PostReview from '@/views/shared/PostReview.vue'
import { isUserLoggedIn } from '@/auth/utils'
import FormSpinButton from '@/views/shared/FormSpinButton.vue'
import NoDataAvailable from './shared/NoDataAvailable.vue'
import CustomCalender from './shared/CustomCalender.vue'

export default {
  components: {
    BImg,
    FormSpinButton,
    BSkeletonTable,
    CartDropdown,
    // PostReview,
    AppCollapse,
    AppCollapseItem,
    NoDataAvailable,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCard,
    BLink,
    BCardBody,
    BCardText,
    BButton,
    BModal,
    BMedia,
    BAvatar,
    BFormSpinbutton,
    CustomCalender,
  },
  props: {
    that: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      seeOrderMenuModal: false,
      seeReviewModal: false,
      seePhotoModal: false,
      seeBookNowModal: false,
      toggleOverview: false,
      sseePromocodesModal: false,
    }
  },
  computed: {
    isLoggedIn() {
      return isUserLoggedIn()
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm A')
    },
    seePromocodes() {
      this.sseePromocodesModal = true
    },
    seeOrderNow() {
      this.seeOrderMenuModal = true
      this.that.itemView = 'list-view'
      if (!this.that.optionsLoaded.products) {
        this.that.getStoreItems()
      }
    },
    seeOverview() {
      this.toggleOverview = !this.toggleOverview
    },
    seeBookNow() {
      this.seeBookNowModal = true
      this.$forceUpdate()
    },
    seeReviewsNow() {
      this.seeReviewModal = true
      if (!this.that.optionsLoaded.reviews) {
        this.that.getStoreReview()
      }
    },
    seePhotos() {
      this.seePhotoModal = true
    },
  },
}
</script>

<style lang="scss">
[dir] body .horizontal-layout.mobile-view-class.navbar-floating:not(.blank-page) .app-content {
  padding-top: 52px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
@import '~@core/scss/base/store-details-responsive.scss';
</style>
<style>
.pr-social-icons{
  padding-right: 7px !important;
}
</style>
